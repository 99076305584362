import React from 'react'
import { FaSquarePhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

export default function Sections() {
    return (
        <>
            {/* Hakkımızda */}
            <section id="hakkimizda" className="py-12 px-4 bg-white flex justify-center items-center">
                <div className="bg-gray-100 p-8 rounded-lg shadow-lg w-2/3 transition duration-500 hover:shadow-2xl">
                    <h2 className="text-3xl font-bold mb-4 text-center text-black">Hakkımızda</h2>
                    <p className="text-gray-700 text-center">
                        Division Company olarak, mobil uygulama geliştirme alanında inovasyon ve mükemmeliyet odaklı hizmet sunmaktayız. Uzman ekibimiz en son teknolojileri takip ederek müşterilerimizin ihtiyaçlarına en uygun çözümleri sunmayı hedeflemektedir.
                    </p>
                </div>
            </section>

            {/* Misyon */}
            <section id="vizyon" className="py-12 px-4 bg-gray-100 flex justify-center items-center">
                <div className="bg-white p-8 rounded-lg shadow-lg w-2/3 transition duration-500 hover:shadow-2xl">
                    <h2 className="text-3xl font-bold mb-4 text-center text-black">Misyonumuz</h2>
                    <p className="text-gray-700 text-center">
                        Teknoloji ve yaratıcılığı bir araya getirerek, en yenilikçi ve etkili mobil uygulama çözümlerini sunmayı hedefliyoruz.
                    </p>
                </div>
            </section>

            {/* Vizyon */}
            <section id="vizyon" className="py-12 px-4 bg-gray-100 flex justify-center items-center">
                <div className="bg-white p-8 rounded-lg shadow-lg w-2/3 transition duration-500 hover:shadow-2xl">
                    <h2 className="text-3xl font-bold mb-4 text-center text-black">Vizyonumuz</h2>
                    <p className="text-gray-700 text-center">
                        Mobil uygulama geliştirme alanında, global çapta tanınan ve tercih edilen bir lider olmayı hedefliyoruz.
                    </p>
                </div>
            </section>

            {/* Değerlerimiz */}
            <section id="vizyon" className="py-12 px-4 bg-gray-100 flex justify-center items-center">
                <div className="bg-white p-8 rounded-lg shadow-lg w-2/3 transition duration-500 hover:shadow-2xl">
                    <h2 className="text-3xl font-bold mb-4 text-center text-black">Değerlerimiz</h2>
                    <div className='m-auto max-w-[600px] w-full'>
                        <p className="text-gray-700 text-left">
                            <span className='font-semibold'>İnovasyon:</span> Sürekli olarak yenilikçi çözümler sunmak. <br />
                            <span className='font-semibold'>Müşteri Odaklılık:</span> Müşteri memnuniyetini her zaman ön planda tutmak<br />
                            <span className='font-semibold'>Kalite:</span> Daima en yüksek kalitede hizmet sağlamak<br />
                            <span className='font-semibold'>Takım Çalışması:</span> Uyumlu ve etkili bir ekip olarak çalışmak<br />
                        </p>
                    </div>
                </div>
            </section>

            {/* İletişim */}
            <section id="iletisim" className="py-12 px-4 bg-white flex justify-center items-center">
                <div className="bg-gray-100 p-8 rounded-lg shadow-lg  transition duration-500 hover:shadow-2xl">
                    <h2 className="text-3xl font-bold mb-4 text-center text-black">İletişim</h2>
                    <p className="text-gray-700 text-center mb-4">
                        Bizimle iletişime geçmek için aşağıdaki bilgileri kullanabilirsiniz:
                    </p>
                    <p className="ml-4 text-gray-700 text-center flex items-center  justify-center gap-2 ">
                        <MdEmail size={25} color='#000090' />
                        <div className='font-semibold '>info@companydivision.com</div></p>
                    <p className="text-gray-700 text-center flex items-center  justify-center gap-2 ">
                        <FaSquarePhone size={23} color='#000090' />
                        <div className='font-semibold'>+90 532 487 28 09</div></p>
                </div>
            </section>
        </>
    )
}
