import { Navigate } from "react-router-dom";
import MainPage from "../pages/mainpage"; // MainPage bileşeni
import MainLayout from "../layouts/main"; // MainLayout import ediliyor
import Team from "../pages/teampage";
import ServicePage from "../pages/servicepage";

const Error = () => {
    return <div>404 Not Found!</div>;
};

const routes = [
    {
        path: "/",
        element: (
            <MainLayout>
                <MainPage /> {/* Ana sayfa MainLayout içinde gösteriliyor */}
            </MainLayout>
        ),
    },
    {
        path: "/not-found",
        element: <Error />,
    }, {
        path: "/team",
        element: (
            <MainLayout>
                <Team />
            </MainLayout>
        ),

    },
    {
        path: "/service",
        element: (
            <MainLayout>
                <ServicePage />
            </MainLayout>
        ),

    },
    {
        path: "*",
        element: <Navigate to="/not-found" />, // 404 sayfasına yönlendirme
    },
];

export default routes;
