import React, { useEffect } from 'react';
import routes from './routes/route';
import { useRoutes } from 'react-router-dom';
import myIcon from './images/footerlogo.png'; // PNG dosyasının yolunu belirtin

function App() {
  const showRoutes = useRoutes(routes);
  useEffect(() => {
    // Favicon öğesini al
    const favicon = document.querySelector("link[rel='icon']");

    // Favicon'un href özelliğini güncelle
    if (favicon) {
      favicon.href = myIcon;
    }
  }, []);
  return (
    <>
      {showRoutes}
    </>
  );
}

export default App;
