import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Header({ isScrolled }) {
    const location = useLocation();
    const hideNav = location.pathname === '/team';

    return (
        <header className={`fixed top-0 left-0 w-full z-50 p-4 transition duration-500 ${isScrolled ? 'bg-black shadow-lg text-white' : 'bg-black text-white'}`}>
            <div className="container mx-auto flex justify-around items-center">
                <Link to={"/"} className="text-xl font-bold">
                    <img src={require('../images/textlogo.png')} alt='header-logo' style={{ height: 20 }} className='h-4' />
                </Link>
                {!hideNav && (
                    <nav>
                        <ul className="flex space-x-4">
                            <li>
                                <Link to="/team" className="hover:text-gray-400 transition duration-300">Ekibimiz</Link>
                            </li>
                            <li>
                                <Link to="/service" className="hover:text-gray-400 transition duration-300">Servislerimiz</Link>
                            </li>

                        </ul>
                    </nav>
                )}
            </div>
        </header>
    )
}
