import React from 'react'

export default function Hero() {
  return (
    <>
  
      {/* Hero Section */}
      <section className="relative h-96 bg-black flex flex-col justify-center items-center">
        <h1 className="text-white text-5xl font-bold">Division </h1>
        <p className="text-white text-xl mt-4">Geleceğinizi birlikte inşa ediyoruz</p>
      </section>
    </>
  )
}
