import React, { useEffect, useState } from 'react'
import Header from "../components/header"
import Footer from "../components/footer"


const MainLayout = ({ children }) => {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 100);
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="flex flex-col min-h-screen bg-gray-100">
            <Header isScrolled={isScrolled} />
            {/* Spacer to prevent content from hiding under fixed header */}
            <div className="pt-14"></div>
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default MainLayout;
