import React from 'react'
import Hero from '../components/hero'
import Sections from '../components/sections'

export default function MainPage() {
  return (
    <>
      <Hero />
      <Sections />
    </>
  )
}


